/* ===========COMMON CSS SART ======================= */

.title-main {
  font-size: 22px;
  font-weight: 400;
}
.title-sm {
  font-size: 20px;
  font-weight: 400;
}
.title-sm-16px {
  font-size: 16px;
  font-weight: 400;
}
.title-sm-14px {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
}
.btn-success {
  background: rgba(123, 97, 255, 0.25);
  border-width: 1px 1px 1px 1px;
  border-style: solid;
  border-color: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
  font-weight: 600;
}
.btn-success:hover,
.btn-success.active {
  background: rgba(123, 97, 255, 0.75);
  color: #ffffff;
}
.active_table{
  background: #7B61FF;
  color: white!important;
}

.text-truncate2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden!important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.sticky-top-custom{
  position: sticky;
  top: -2px; /* Don't forget this, required for the stickiness */
 
}
.table-height{
 
  max-height: 500px;
  overflow-y:scroll;
}
/* ===========COMMON CSS EDN=============  */

/* ==============SIDE BAR CSS START */

.side-bar {
  position: fixed;
  width: 100px;
  background: white;
  background: #ffffff;
  border-right: 1px solid #737373;
  height: 100%;
}
.side-bar ul {
  text-align: center;
}
.side-bar ul li {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 12px;
}
.side-bar ul li a {
  color: #1e1e1e;
}
.side-bar ul li .icon {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}
.side-bar ul li:hover .icon svg path {
  fill: #7b61ff;
}
.side-bar ul li:first-child {
  padding-top: 0px;
  padding-bottom: 0px;
}
.recipe-star-icon {
  position: absolute;
  right: 0;
  bottom: 7px;
}
.recipe-star-icon svg {
  width: 16px;
}
.sticky-top {
  position: sticky;
  top: 0px;
  z-index: 99;
  background: white;
}

/* ==========CLOSE SIDE BAR CSS ======== */

/* ============ TABLE VIEW CSS  START =======*/

.table {
  border-collapse: collapse;
  width: 100%;
}

.table th,
.table td {
  border: 1px solid #B9B9B9;
  padding: 8px;
  text-align: left;
  font-size: 12px;
  height: 15px;
}

.table th {
  background-color: #f2f2f2;
}
/* .table th:first-child {
  padding: 0px;
  text-align: center;
} */
/* .table td:first-child {
  padding: 0px;
  text-align: center;
  background: #7b61ff;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #b9b9b9;
  color: white;
} */
.table-container {
  overflow-y: scroll;
  width: 100%;
}
.timer-wrapper > div, .timer-wrapper svg{
  height: 55px!important;
  width: 55px!important;
}
.timer-wrapper-color > div, .timer-wrapper svg{
  height: 55px!important;
  width: 55px!important;
  color: gray
}
  ::-webkit-scrollbar {
   width: 10px;
 }
 
 /* Track */
 ::-webkit-scrollbar-track {
   background: #f1f1f1; 
 }
  
 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: #888; 
 }
 
 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: #555; 
 }

 .text-color{
  color: #7B61FF
 }
/* ============ TABLE VIEW CSS  CLOSE =======*/

@media only screen and (min-width: 768px) {
  .side-bar {
    width: 137px;
  }
}
@media only screen and (max-width: 768px) {
  .side-bar {
    width: 100%;
    bottom: 0px;
    height: 68px;
    border-top: 1px solid #737373;
    background-color: white;
    z-index: 9999;
  }
  .side-bar ul li {
    padding-top: 0px;
    padding-bottom: 10px;
    display: inline !important;
  }
  .side-bar ul {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    padding-bottom: 20px;
    align-items: center;
  }
  .table-height{
    max-height: 500px;
    overflow:scroll;
  }



}
@media only screen and (max-width: 639px) {
  .flex-custom{
    display: flex;
    justify-content: space-between;
  }
}
